import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import b1 from "./imgs/btm-r-img.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>越南</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>VN</h2>
            <h3 className={an3}>越南公司注册</h3>
            <h3 className={an3}>Company Registration In Viet Nam</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册越南公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Viet Nam
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>
                  全球产业链已经出现部分迁移，这引起多个东南亚国家的重视，
                  <br></br>
                  其中越南成为颇受瞩目的“赢家”。
                </div>
              </div>
            </div>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>
                  随着越南经济快速发展，政府积极招商引资，<br></br>
                  人口红利逐渐浮现水面，越来越多中国企业都前往越南投资。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册越南公司类型</h2>
              <div className={s.subTitle}>
                Types Of Incorporation In Viet Nam
              </div>
              <ul>
                <li className={s.s2P}>
                  外商独资有限责任公司(LLC)、中外合 资有限公司、分公司及代表处。
                </li>
                <li className={s.s2P}>
                  有限责任公司(LLC)：在越南可以设立100%外资持有的有限责任公司，只需一名股东，任何国籍、是否越南居民皆可。
                </li>
                <li className={s.s2P}>
                  联营公司(JV)：也被称为部分由外资持有的有限责任公司，这是在越南最常见的境外公司类型。
                </li>
                <li className={s.s2P}>
                  分公司：经营至少5年的外国公司可以在越南设立分公司，该公司必须指派一位驻越南代表，并同时履行其他义务。
                </li>
                <li className={s.s2P}>
                  代表处：在海外经营了一年的公司，可以在越南开设代表处。尽管代表处是外资100%持有，但可以从事商业或生产相关活动。
                </li>
              </ul>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
