import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import b1 from "./imgs/btm-r-img.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>英属维尔京群岛</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>BVI</h2>
            <h3 className={an3}>英属维尔京群岛公司注册</h3>
            <h3 className={an3}>
              Company Registration In British Virgin Islands
            </h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册英属维尔京群岛公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In British Virgin Islands
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>
                  <p>政治、经济和贸易环境非常稳定的地方；</p>
                  <p>
                    有良好的金融法律设施，方便建立各种金融机构或基金会的成立与发展；
                  </p>
                  <p>保护股东利益，不需要公布受益人身份；董事资料绝对保密；</p>
                  <p>一个人可完全拥有一家有限公司；</p>
                </div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>
                  <p>低税率，公司所受的税务管制非常少；</p>
                  <p>不需核数师报告，只需保留资料反映经济状况已可；</p>
                  <p>
                    在外地经营所得利润无须交利得税，以三角贸易形式可以达到合理避税的目的；
                  </p>
                  <p>岛上企业在世界各地均可开立银行账户。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
