import React from "react";
import logo from "./logo.svg";
import s from "./app.module.css";
import { Link } from "react-router-dom";
import HomePage from "./home";
import HKPage from "./hk";
import FTZPage from "./ftz";
import VietnamPage from "./vietnam";
import BVIPage from "./bvi";
import KYPage from "./ky";
import USPage from "./us";
import MYSPage from "./mys";
import SGPage from "./sg";
import BrandPage from "./brand";
import RegisterPage from "./register";
import AboutPage from "./about";
import StepPage from "./step";
import ContactPage from "./contact";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import logoIcon from "./img/logo2.png";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";

const FadeTransition = ({ shouldShow, timeout, classNames, children }: any) => {
  return (
    <CSSTransition timeout={timeout} classNames={classNames} in={shouldShow}>
      {children}
    </CSSTransition>
  );
};

function Content() {
  const [showNav, setShowNav] = React.useState(false);
  const navigate = useNavigate();
  const isHome = window.location.pathname === "/";
  const isAbout =
    window.location.pathname && window.location.pathname.indexOf("about") >= 0;
  const path = window.location.pathname;
  const location = useLocation();
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [path]);
  return (
    <div
      className={s.wrap}
      onClick={() => {
        setShowNav(false);
      }}
    >
      <div className={s.header}>
        <div className={s.headerInner}>
          <img
            src={logoIcon}
            onClick={() => {
              navigate("/");
            }}
          />
          <div
            className={s.navMobile}
            onClick={(e) => {
              setShowNav(!showNav);
              e.stopPropagation();
            }}
          >
            <span />
            <span />
            <span />
          </div>
          <div className={s.mNav}>
            <div
              className={
                showNav
                  ? `${s.navMobileWrap} ${s.navMobileWrapShow}`
                  : s.navMobileWrap
              }
            >
              <span
                onClick={() => {
                  navigate("/");
                  setShowNav(false);
                }}
                className={isHome ? s.active : ""}
              >
                首页
              </span>
              <span
                className={isAbout ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/register");
                }}
              >
                公司注册
              </span>
              <span
                className={isAbout ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/step");
                }}
              >
                银行开户
              </span>
              <span
                className={isAbout ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/brand");
                }}
              >
                商标注册
              </span>
              <span
                className={isAbout ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/contact");
                }}
              >
                联系我们
              </span>
            </div>
          </div>
          <div className={s.headerNavWrap}>
            <span
              onClick={() => {
                navigate("/");
              }}
              className={isHome ? s.active : ""}
            >
              首页
            </span>
            <span
              className={isAbout ? s.active : ""}
              onClick={() => {
                navigate("/register");
              }}
            >
              公司注册
            </span>
            <span
              className={isAbout ? s.active : ""}
              onClick={() => {
                navigate("/step");
              }}
            >
              银行开户
            </span>
            <span
              className={isAbout ? s.active : ""}
              onClick={() => {
                navigate("/brand");
              }}
            >
              商标注册
            </span>
            <span
              className={isAbout ? s.active : ""}
              onClick={() => {
                navigate("/contact");
              }}
            >
              联系我们
            </span>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={2000}>
            <Routes>
              <Route path="/about" element={<AboutPage />} />
              <Route path="/brand" element={<BrandPage />} />
              <Route path="/hk" element={<HKPage />} />
              <Route path="/ftz" element={<FTZPage />} />
              <Route path="/ky" element={<KYPage />} />
              <Route path="/vietnam" element={<VietnamPage />} />
              <Route path="/bvi" element={<BVIPage />} />
              <Route path="/mys" element={<MYSPage />} />
              <Route path="/us" element={<USPage />} />
              <Route path="/sg" element={<SGPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/step" element={<StepPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className={s.footer}>
        <div className={s.footerInner}>
          Copyright © HAIQI INTERNATIONAL PTE LTD. 版权所有 All rights reserved.
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

export default App;
