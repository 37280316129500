import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/home1.png";
import Im2 from "../img/home2.png";
import Im3 from "../img/home3.png";
function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState("");
  const [an2, setAn2] = React.useState("");
  const [an3, setAn3] = React.useState(s.maskLeft);
  const [an4, setAn4] = React.useState(s.maskRight);
  const [an5, setAn5] = React.useState(s.sub);

  React.useEffect(() => {
    setAn1(s.play);
    setTimeout(() => {
      setAn2(s.play2);
    }, 600);
    setTimeout(() => {
      setAn3(`${s.maskLeft} ${s.maskLeft} ${s.maskLeftPlay}`);
      setAn4(`${s.maskRight} ${s.maskRight} ${s.maskRightPlay}`);
      setAn1(`${s.play} ${s.playDone}`);
      setAn2(`${s.play2} ${s.play2Done}`);
      setAn5(`${s.sub} ${s.subPlay}`);
    }, 2000);
  }, []);
  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={an3}></div>
        <div className={an4}></div>
        <div className={s.fullContent}>
          <h2 className={an1}>HAIQI</h2>
          <h2 className={an2}>海企国际</h2>
          <h3 className={an5}>HAIQI INTERNATIONAL PTE. LTD</h3>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>公司介绍</h2>
          <div className={s.subTitle}>Company Introduction</div>
          <div className={s.s1Content}>
            <p>
              海企国际（HAIQI INTERNATIONAL PTE.
              LTD.）是为国内企业及个人在海外提供专业财税、商标专利、营业资质、银行商务、投资移民服务的公司，由会计、税务、财
              务、金融、商业秘书、法律、翻译等专业方面的高级资深顾问组成的团队，目前拥有新加坡、马来西亚、美国、北京、上海、深圳等合伙人办事处；为中国及海外各地
              客户提供多元化商务集团服务。
            </p>
            <p>
              古往今来，海企国际宗旨一直未变，依然是助全球企业及个人走出去、抓住时代机遇、开拓崭新的市场、
              收获丰厚硕果。万物吉祥，皆因有您！未来世界变化多端，我们对客户服务始终如一，愿我们携手共进，合作共赢。
            </p>
          </div>
        </div>
      </div>
      <div className={s.full}>
        <img src={Im2} />
      </div>
      <div className={s.content}>
        <div className={s.s2}>
          <h2 className={s.title}>服务特色</h2>
          <div className={s.subTitle}>Service features</div>
          <div className={s.s2List}>
            <div className={s.s2Item}>
              <div className={s.s2ItemTitle}>时间要求最快</div>
              <div className={s.s2ItemTitle}>效率保证最高</div>
              <div className={s.s2ItemContent}>
                业内首家提出涉外综合服务的专业服务商，满足客户全方位的需求，提供多元化一站式服务。
              </div>
            </div>
            <div className={s.s2Item}>
              <div className={s.s2ItemTitle}>整合全球资源</div>
              <div className={s.s2ItemTitle}>提供综合服务</div>
              <div className={s.s2ItemContent}>
                最快时间调取客户所需资源，不分节假日，第一时间高效回复，减少等待时间。
              </div>
            </div>
            <div className={s.s2Item}>
              <div className={s.s2ItemImg} />
            </div>
            <div className={s.s2Item}>
              <div className={s.s2ItemTitle}>了解客户需求</div>
              <div className={s.s2ItemTitle}>创新产品方案</div>
              <div className={s.s2ItemContent}>
                紧盯客户与市场的需求，不断创新产品，高效升级方案，多维度满足客户需求。
              </div>
            </div>
            <div className={s.s2Item}>
              <div className={s.s2ItemTitle}>拥抱合规合法</div>
              <div className={s.s2ItemTitle}>忠诚尊重客户</div>
              <div className={s.s2ItemContent}>
                我们配合并要求客户提供合规合法文件，严格保密客户的任何隐私并忠诚于客户。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
