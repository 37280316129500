import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
function Page() {
  let navigate = useNavigate();
  return (
    <div className={s.wrap}>
      <h1>About page</h1>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </button>
    </div>
  );
}

export default Page;
