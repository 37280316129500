import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import S1Icon from "../img/s1.jpg";
import S2Icon from "../img/s2.jpg";
import S3Icon from "../img/s3.jpg";
import S4Icon from "../img/s4.jpg";
import S5Icon from "../img/s5.jpg";
import S6Icon from "../img/s6.jpg";
import S7Icon from "../img/s7.jpg";

import B1Icon from "../img/ss1.jpg";
import B2Icon from "../img/ss2.jpg";
import B3Icon from "../img/ss3.jpg";
import B4Icon from "../img/ss4.jpg";
import B5Icon from "../img/ss5.jpg";
import B6Icon from "../img/ss6.jpg";
import B7Icon from "../img/ss7.jpg";
import B8Icon from "../img/ss8.jpg";
import B9Icon from "../img/ss9.jpg";
import B10Icon from "../img/ss10.jpg";
import B11Icon from "../img/ss11.jpg";
import B12Icon from "../img/ss12.jpg";

import BBIcon from "../img/sss1.jpg";
function Page() {
  let navigate = useNavigate();
  return (
    <div className={s.wrap}>
      <div className={s.s1}>
        <h2 className={s.title}>海外开户步骤</h2>
        <div className={s.subTitle}>Steps For Opening A HAIQI Account</div>
      </div>
      <div className={s.content}>
        <div className={s.s2}>
          <div className={s.s2Left}>
            <div>公司户</div>
            <div>个人户</div>
          </div>
          <div className={s.s2Right}>
            <div className={s.s2List}>
              <div className={s.s2Item}>
                <img src={S1Icon} />
                <p>合作洽谈</p>
              </div>
              <img src={S7Icon} />
              <div className={s.s2Item}>
                <img src={S2Icon} />
                <p>专家预审</p>
              </div>
              <img src={S7Icon} />
              <div className={s.s2Item}>
                <img src={S3Icon} />
                <p>资料补充</p>
              </div>
              <img src={S7Icon} />
              <div className={s.s2Item}>
                <img src={S4Icon} />
                <p>银行复审</p>
              </div>
              <img src={S7Icon} />
              <div className={s.s2Item}>
                <img src={S5Icon} />
                <p>预约开户</p>
              </div>
              <img src={S7Icon} />
              <div className={s.s2Item}>
                <img src={S6Icon} />
                <p>开户成功</p>
              </div>
            </div>
            <div className={s.s2List2}>
              <div className={s.s2ItemTitle}>
                <p>公司户</p>
              </div>
              <div className={s.s2List2Item}>
                <p>提前审核材料</p>
                <p>介绍适合的银行和方案</p>
              </div>
              <div className={s.s2List2Item}>
                <p>VIP通道快速预约开户</p>
                <p>解决排队久的问题</p>
              </div>
              <div className={s.s2List2Item}>
                <p>提前审核资料是否齐全</p>
                <p>确保面签一次通过</p>
              </div>
              <div className={s.s2List2Item}>
                <p>开户前后有保障</p>
                <p>不成功全额退款</p>
              </div>
            </div>
            <div className={s.s2List2}>
              <div className={s.s2ItemTitle}>
                <p>个人户</p>
              </div>
              <div className={s.s2List2Item}>
                <p>无需购买理财</p>
                <p>无需购买保险</p>
              </div>
              <div className={s.s2List2Item}>
                <p>仅需身份证及港澳通行证</p>
                <p>无需地址证明</p>
              </div>
              <div className={s.s2List2Item}>
                <p>最快当天可安排开户</p>
                <p>当天拿账户</p>
              </div>
              <div className={s.s2List2Item}>
                <p>开户前后有保障</p>
                <p>不成功全额退款</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.s3}>
        <div className={s.content}>
          <h2 className={s.title}>合作银行内部通道</h2>
          <div className={s.subTitle}>Internal Channel Of Cooperative Bank</div>
          <div className={s.s3Content}>
            <div className={s.s3Row}>
              <div className={s.s3ImgWrap}>
                <img src={B1Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B2Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B3Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B4Icon} />
              </div>
            </div>
            <div className={s.s3Row}>
              <div className={s.s3ImgWrap}>
                <img src={B5Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B6Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B7Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B8Icon} />
              </div>
            </div>
            <div className={s.s3Row}>
              <div className={s.s3ImgWrap}>
                <img src={B9Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B10Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B11Icon} />
              </div>
              <div className={s.s3ImgWrap}>
                <img src={B12Icon} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.content}>
        <div className={s.s4}>
          <h2 className={s.title}>境外账户维护指导</h2>
          <div className={s.subTitle}>Overseas Account Maintenance Guide</div>
          <div className={s.s4List}>
            <div className={s.s4Item}>
              <div className={s.s4ItemTitle}>及时回复</div>
              <div className={s.s4ItemTitle}>银行信息</div>
              <div className={s.s4ItemContent}>
                <p>确保预留给银行的通讯信息真实有效</p>
                <p>若收到银行发生的文件信息务必及时回复</p>
              </div>
            </div>
            <div className={s.s4Item}>
              <div className={s.s4ItemTitle}>保持账户</div>
              <div className={s.s4ItemTitle}>活跃性</div>
              <div className={s.s4ItemContent}>
                <p>及时激活，并进行存款</p>
                <p>每月登陆1-2次网银，近三个月内进行3-5次交易</p>
                <p>保持账户资金满足各银行日均余额要求</p>
                <p>避免不使用账户或长时间不发生业务往来</p>
              </div>
            </div>
            <div className={s.s4Item}>
              <div className={s.s4ItemImg} />
            </div>
            <div className={s.s4Item}>
              <div className={s.s4ItemTitle}>确保交易</div>
              <div className={s.s4ItemTitle}>安全性</div>
              <div className={s.s4ItemContent}>
                <p>避免与敏感、高危地区进行交易</p>
                <p>尽量采取对公汇款，减少个人交易</p>
                <p>避免不合理的大笔资金进出账户</p>
              </div>
            </div>
            <div className={s.s4Item}>
              <div className={s.s4ItemTitle}>合规运营</div>
              <div className={s.s4ItemTitle}>依法维护</div>
              <div className={s.s4ItemContent}>
                <p>境外公司合规运营，正常做账报税</p>
                <p>妥善保管相关业务资料、账册，以备银行不定期抽查</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
