import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/hk1.jpg";
import I1 from "../img/hks1.jpg";
import I2 from "../img/hks2.jpg";
import I3 from "../img/hks3.jpg";
import I4 from "../img/hks4.jpg";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>香港</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>HK</h2>
            <h3 className={an3}>香港公司注册</h3>
            <h3 className={an3}>Company Registration In Hong Kong</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册香港公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Hong Kong
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>提高公司形象</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>增强客户信心</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I3} />
                <div className={s.s1Content1ItemValue}>拓展国际市场</div>
              </div>
            </div>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I4} />
                <div className={s.s1Content2ItemValue}>
                  香港是全球性金融中心，国际自由贸易港，拥有良好的基础设施和健全的法律制度,
                  税率低，税种少。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册香港公司条件</h2>
              <div className={s.subTitle}>
                Conditions For Registration Of A Hong Kong's Company
              </div>
              <div className={s.s2P}>
                一位或一位以上年满18周岁的股东（出示有效身份证或护照影印件，企业法
                人股东提交营业执照和法人代表的身份证复印件）。
              </div>
              <div className={s.s2C}>
                海企可为客户提供香港公司注册地址、香港公司法定秘书。注册资本无须验资。
              </div>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
