import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im2 from "./imgs/bottom-img.png";
import InfoIcon from "../img/info.png";
function Page() {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [market, setMarket] = React.useState("新加坡");
  const [content, setContent] = React.useState("");
  let navigate = useNavigate();
  const body = encodeURIComponent(`
    我是${name}, 计划成立${market}公司

    ${content}

    我的联系邮箱是: ${email}
  `);
  const aUrl = `mailto:haiqibd@163.com?subject=商业合作咨询-${name}&body=${body}`;
  return (
    <div className={s.wrap}>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>联系我们</h2>
          <div className={s.subTitle}>Contact Us</div>
          <div className={s.s1ContentWrap}>
            <div className={s.c1Item}>
              <div className={s.c1label}>选择公司</div>
              <div className={s.c1Value}>
                <select
                  defaultChecked={false}
                  defaultValue="新加坡"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setMarket(e.target.value);
                  }}
                >
                  <option value="新加坡">新加坡</option>
                  <option value="香港">香港</option>
                  <option value="新加坡">马来西亚</option>
                  <option value="香港">美国</option>
                  <option value="新加坡">开曼群岛</option>
                  <option value="香港">英属维尔京群岛</option>
                  <option value="新加坡">越南</option>
                  <option value="香港">自贸区</option>
                </select>
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>姓名</div>
              <div className={s.c1Value}>
                <input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>您的联系邮箱</div>
              <div className={s.c1Value}>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Item}>
              <div className={s.c1label}>输入信息</div>
              <div className={s.c1Value}>
                <textarea
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c1Button}>
              <span
                onClick={() => {
                  if (!name || !content || !email || !market) {
                    return;
                  }
                  window.open(aUrl);
                }}
                className={
                  !name || !content || !email || !market ? s.disable : s.normal
                }
              >
                Submit
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={s.full}>
        <img src={Im2} />
      </div>
    </div>
  );
}

export default Page;
