import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import I3 from "./imgs/icon-3.png";
import I4 from "./imgs/icon-4.png";
import I5 from "./imgs/icon-5.png";
import I6 from "./imgs/icon-6.png";
import b1 from "./imgs/btm-r-img.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>美国</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>US</h2>
            <h3 className={an3}>美国公司注册</h3>
            <h3 className={an3}>Company Registration In United States</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册美国公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In United States
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>名称选择自由</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>营商基础设施良好</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I3} />
                <div className={s.s1Content1ItemValue}>
                  持美国公司邀请函快速办理护照和签证
                </div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I4} />
                <div className={s.s1Content1ItemValue}>
                  没有外汇管制资金进出自由
                </div>
              </div>
            </div>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I5} />
                <div className={s.s1Content1ItemValue}>
                  在美国、加拿大注册公司有助于您获得各类商务和工作签证。
                </div>
              </div>
              <div className={s.s1Content2Item}>
                <img src={I6} />
                <div className={s.s1Content1ItemValue}>
                  一年以后美国公司仍然存在，可以透过律师向美国官方申请居留卡，
                  达至移民目的。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册美国公司条件</h2>
              <div className={s.subTitle}>
                Conditions For Registration Of A United States's Company
              </div>
              <ul>
                <li className={s.s2P}>
                  提供一人股东或两个以上股东护照或身份证 (18 岁以上) 复印件;
                </li>
                <li className={s.s2P}>
                  提供三个美国公司名称，经美国公司注册登记机构核名如无重复则可申请;
                </li>
                <li className={s.s2P}>
                  提供申请美国公司其公司主席、总裁、董事长、秘书、财务主管姓名;
                </li>
                <li className={s.s2P}>
                  申请注册美国公司每个股东所占的股份比例(%)。
                </li>
              </ul>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
