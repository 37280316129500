import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import I3 from "./imgs/icon-3.png";
import I4 from "./imgs/icon-4.png";
import I5 from "./imgs/icon-5.png";
import I6 from "./imgs/icon-6.png";
import b1 from "./imgs/btm-r-img.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>马来西亚</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>MYS</h2>
            <h3 className={an3}>马来西亚公司注册</h3>
            <h3 className={an3}>Company Registration In Malaysia</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册马来西亚公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Malaysia
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>基础建设非常完善</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I4} />
                <div className={s.s1Content1ItemValue}>
                  企业在世界各地均可开立银行帐户
                </div>
              </div>
            </div>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I3} />
                <div className={s.s1Content1ItemValue}>
                  马来西亚是政治、经济和贸易环境非常稳定的地方。
                </div>
              </div>
            </div>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>拓展东盟10国的跳板</div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I5} />
                <div className={s.s1Content1ItemValue}>
                  可以注册中文公司名称与印制中文章程
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册马来西亚公司条件</h2>
              <div className={s.subTitle}>
                Conditions For Registering A Malaysia's Company
              </div>
              <ul>
                <li className={s.s2P}>一位或一位以上年満18周岁人士 即可;</li>
                <li className={s.s2P}>
                  在成立公司前必须先确定股东(至少1位) 、董事(至少1位，允
                  许外籍自然人士担任) ，并安排好各股东的股份比例;
                </li>
                <li className={s.s2P}>
                  在马来西亚成立公司 沒有注册资本要求，注册资本不用到位;
                </li>
                <li className={s.s2P}>公司名称只需要有一个英文名称即可;</li>
                <li className={s.s2P}>
                  公司秘书：必须符合马来西亚公司秘书资格且居住在马来西亚。
                </li>
              </ul>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
