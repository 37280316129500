import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/register3.jpg";
import Info1Icon from "../img/r1.jpg";
import Info2Icon from "../img/r2.jpg";
import Info3Icon from "../img/r3.jpg";
import Info4Icon from "../img/r4.jpg";
import Info5Icon from "../img/r5.jpg";
import Info6Icon from "../img/r6.jpg";

import Map1Icon from "../img/rr1.jpg";
import Map2Icon from "../img/rr2.jpg";
import Map3Icon from "../img/rr3.jpg";
import Map4Icon from "../img/rr4.jpg";
import Map5Icon from "../img/rr5.jpg";
import Map6Icon from "../img/rr6.jpg";
import Map7Icon from "../img/rr7.jpg";
import Map8Icon from "../img/rr8.jpg";

import B1Icon from "./imgs/icon-btm-1.png";
import B2Icon from "./imgs/icon-btm-2.png";
import B3Icon from "./imgs/icon-btm-3.png";
import B4Icon from "./imgs/icon-btm-4.png";
function Page() {
  let navigate = useNavigate();
  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <div className={s.s1Item}>
            <img src={Info1Icon} />
            <p>税务筹划</p>
          </div>
          <div className={s.s1Item}>
            <img src={Info2Icon} />
            <p>控股其他公司</p>
          </div>
          <div className={s.s1Item}>
            <img src={Info3Icon} />
            <p>国际贸易</p>
          </div>
          <div className={s.s1Item}>
            <img src={Info4Icon} />
            <p>商业性投资</p>
          </div>
          <div className={s.s1Item}>
            <img src={Info5Icon} />
            <p>境外上市</p>
          </div>
          <div className={s.s1Item}>
            <img src={Info6Icon} />
            <p>提高国际形象</p>
          </div>
        </div>
      </div>

      <div className={s.content}>
        <div className={s.s2}>
          <div
            className={s.s2Item}
            onClick={() => {
              navigate("/hk");
            }}
          >
            <img src={Map1Icon} />
            <p>香港</p>
          </div>
          <div
            onClick={() => {
              navigate("/sg");
            }}
            className={s.s2Item}
          >
            <img src={Map2Icon} />
            <p>新加坡</p>
          </div>
          <div
            onClick={() => {
              navigate("/mys");
            }}
            className={s.s2Item}
          >
            <img src={Map3Icon} />
            <p>马来西亚</p>
          </div>
          <div
            onClick={() => {
              navigate("/us");
            }}
            className={s.s2Item}
          >
            <img src={Map4Icon} />
            <p>美国</p>
          </div>
        </div>
        <div className={s.s2}>
          <div
            className={s.s2Item}
            onClick={() => {
              navigate("/ky");
            }}
          >
            <img src={Map5Icon} />
            <p>开曼群岛</p>
          </div>
          <div
            onClick={() => {
              navigate("/bvi");
            }}
            className={s.s2Item}
          >
            <img src={Map6Icon} />
            <p>英属维尔京群岛</p>
          </div>
          <div
            onClick={() => {
              navigate("/vietnam");
            }}
            className={s.s2Item}
          >
            <img src={Map7Icon} />
            <p>越南</p>
          </div>
          <div
            onClick={() => {
              navigate("/ftz");
            }}
            className={s.s2Item}
          >
            <img src={Map8Icon} />
            <p>自贸区</p>
          </div>
        </div>
      </div>
      <div className={s.s3}>
        <div className={s.content}>
          <div className={s.s3Content}>
            <div className={s.s3Item}>
              <img src={B1Icon} />
              <p>根据不同需求</p>
              <p>为客户制定专业</p>
              <p>快捷的解决方案</p>
            </div>
            <div className={s.s3Item}>
              <img src={B2Icon} />
              <p>价格透明</p>
              <p>统一报价</p>
              <p>无隐形消费</p>
            </div>
            <div className={s.s3Item}>
              <img src={B3Icon} />
              <p>一站式专业服务</p>
              <p>从注册到年审</p>
              <p>开户到报税</p>
              <p>提供全方面协助</p>
            </div>
            <div className={s.s3Item}>
              <img src={B4Icon} />
              <p>我司拥有完善的</p>
              <p>保密系统</p>
              <p>保护客户隐私</p>
              <p>不外泄</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
