import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/header-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import I3 from "./imgs/icon-3.png";
import I4 from "./imgs/icon-4.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>新加坡</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>SG</h2>
            <h3 className={an3}>新加坡公司注册</h3>
            <h3 className={an3}>Company Registration In Singapore</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册新加坡公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Singapore
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <div className={s.s1Content1ItemValue}>
                  新加坡向来是美国、 欧洲与日本公司设立亚洲总部的首选地点。
                </div>
                <img src={I1} />
              </div>
            </div>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>
                  新加坡不仅在国际金融、贸易融资、海事金融、保险、财务运作方面拥有领先地位。
                </div>
              </div>
            </div>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <div className={s.s1Content1ItemValue}>
                  新加坡已建立了健全的研发(R&D)
                  生态系统和完善的知识产权(IP)保障制度。
                </div>
                <img src={I3} />
              </div>
            </div>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I4} />
                <div className={s.s1Content1ItemValue}>
                  新加坡是各大知识产权国际公约与条约如专利合作与
                  贸易有关的知识产权协议。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册新加坡公司条件</h2>
              <div className={s.subTitle}>
                Conditions For Registration Of A Singapore's Company
              </div>
              <div className={s.s2P}>
                如果你是外国人，自有新加坡居民董事，或你是新加坡公民或新加坡永久居民，可以用SINGPASS
                登陆新加坡会计与企业管制局ACRA官网www.acra.gov.sg注册，
                否则你需要找一个有秘书牌照的代理人RFA （ REGISTED FILING
                AGENT）帮忙。
              </div>
              <div className={s.s2C}>
                新加坡ACRA从2015年就实施注册秘书代理人RFA发牌制度。寻求有牌照的秘书代
                理人注册，是保护你的利益的第一步！
                你有权要求你的代理公司出示代理牌照RFA！
              </div>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
