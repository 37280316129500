import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "../img/brand-1.jpg";
import InfoIcon from "../img/info.png";
function Page() {
  let navigate = useNavigate();
  return (
    <div className={s.wrap}>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册商标的好处</h2>
          <div className={s.subTitle}>
            The Benefits Of A Registered Trademark
          </div>
          <div className={s.s1ContentWrap}>
            <div className={s.s1ContentLeft}>
              <div className={s.s1Title}>
                必备资质
                <span className={s.s1Icon} />
              </div>
              <div className={s.s1Content}>入驻大型超市、正规网店电商平台</div>
              <div className={s.s1Content}>
                媒体广告投放、微博微信官方认证等必要资质
              </div>
              <div className={s.s1Title}>
                无形资产
                <span className={s.s1Icon} />
              </div>
              <div className={s.s1Content}>
                可作为无形资产，进行转让、继承、
              </div>
              <div className={s.s1Content}>
                资产投资、抵押贷款等，具有非常高的经济价值
              </div>
            </div>
            <div className={s.s1ContentMid}>
              <div className={s.s1MidImg}>
                <img src={InfoIcon} />
              </div>
              <div className={s.s1MidTitle}>未注册商标的风险</div>
              <div className={s.s1MidContent}>1. 被别人恶意抢注。</div>
              <div className={s.s1MidContent}>
                2. 使用人不享有商标专用权，任何人可以随意使用。
              </div>
              <div className={s.s1MidContent}>
                3.
                在相同或者类似商品使用与已注册商标相同或者近似的商标，从而发生侵权行为。
              </div>
            </div>
            <div className={s.s1ContentRight}>
              <div className={s.s1Title}>
                <span className={s.s1Icon} />
                建立品牌效果
              </div>
              <div className={s.s1Content}>®商标既是品牌</div>
              <div className={s.s1Content}>便于消费者认牌购物，抢占市场</div>
              <div className={s.s1Title}>
                <span className={s.s1Icon} />
                品牌保护
              </div>
              <div className={s.s1Content}>商标受法律保护</div>
              <div className={s.s1Content}>
                避免行业内恶性竞争，防止同类企业侵权
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.s2Header}>
          <div className={s.content}>
            <h2 className={s.title}>注册商标的好处</h2>
            <div className={s.subTitle}>
              The Benefits Of A Registered Trademark
            </div>
          </div>
        </div>
        <div className={s.full}>
          <img src={Im1} />
        </div>
        <div className={s.s2Content}>
          <div className={s.content}>
            <div className={s.s2InfoContent}>
              1.通过法定程序确定发明创造的权利归属关系，从而有效保护发明创造成果，独占市场，以此换取最大的利益；
            </div>
            <div className={s.s2InfoContent}>
              2.为了在市场竞争中争取主动，确保自身生产与销售的安全性，防止对手拿专利状告自己侵权（遭受高额经济赔偿、迫使自己停止生产与销售);
            </div>
            <div className={s.s2InfoContent}>
              3.专利权受到国家专利法保护，未经专利权人同意许可，任何单位或个人都不能使用（状告他人侵犯专利权，索取赔偿);
            </div>
            <div className={s.s2InfoContent}>
              4.自己的发明创造及时申请专利，使自己的发明创造得到国家法律保护，防止他人模仿本企业开发的新技术、新产品（构成技术壁垒，别人要想研发类似技术或产品就必须得经专利权人同意）；
            </div>
            <div className={s.s2InfoContent}>
              5.自己的发明创造如果不及时申请专利，别人把你的劳动成果提出专利申请，反过来向法院或专利管理机构告你侵犯专利权；
            </div>
            <div className={s.s2InfoContent}>
              6.可以促进产品的更新换代，亦提高产品的技术含量，及提高产品的质量、降低成本，使企业的产品在市场竞争中立于不败之地；
            </div>
            <div className={s.s2InfoContent}>
              7.一个企业若拥有多个专利是企业强大实力的体现，是一种无形资产和无形宣传（拥有自主知识产权的企业既是消费者趋之若鹜的强力企业，同时也是政府各项政策扶持的主要目标群体），21世纪是知识经济的时代，世界未来的竞争，就
              是知识产权的竞争；
            </div>
            <div className={s.s2InfoContent}>
              8.专利技术可以作为商品出售（转让），比单纯的技术转让更有法律和经济效益，从而达到其经济价值的实现；
            </div>
            <div className={s.s2InfoContent}>9.专利宣传效果好；</div>
            <div className={s.s2InfoContent}>10.避免会展上撤下展品的尴尬；</div>
            <div className={s.s2InfoContent}>
              11.国家对专利申请有一定的扶持政策（如政府颁布的专利奖励政策、以及高新技术企业政策等），会给予部分政策、经济方面的帮助；
            </div>
            <div className={s.s2InfoContent}>
              12.专利除具有以上功能外，拥有一定数量的专利还作为企业上市和其
              他评审中的一项重要指标，比如：高新技术企业资格评审、科技项目的验收和评审等，专利还具有科研成果市场化的桥梁作用。总之，专利既可用作盾，保护自己的技术
              和产品；也可用作矛，打击对手的侵权行为。充分利用专利的各项功能，对企业的生产经营具有极大的促进作用。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
