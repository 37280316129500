import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import I3 from "./imgs/icon-3.png";
import b1 from "./imgs/btm-r-img.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>开曼群岛</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>KY</h2>
            <h3 className={an3}>开曼群岛公司注册</h3>
            <h3 className={an3}>Company Registration In Cayman Islands</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册开曼群岛公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Cayamn Islands
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I1} />
                <div className={s.s1Content2ItemValue}>
                  开曼群岛没有附加条例限制贸易发展，没有外汇管制，
                  除年度牌照费外开曼公司不需申报或缴纳任何税项。
                </div>
              </div>
            </div>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>
                  开曼群岛是国际投资者在海外设立离岸公司最具代表性的热点地区。
                </div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I3} />
                <div className={s.s1Content1ItemValue}>
                  开曼公司商业运作方便，税收制度合理
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.s2}>
        <div className={s.content}>
          <div className={s.s2Content}>
            <div className={s.s2Left}>
              <h2 className={s.title}>注册开曼公司条件</h2>
              <div className={s.subTitle}>
                Conditions For Registration Of A Cayamn Islands' Company
              </div>
              <ul>
                <li className={s.s2P}>
                  最少一名股东，可以是自然人或法人，没有国籍上的限制。开曼群岛离岸公司可选择发行记名或不记名股票；
                </li>
                <li className={s.s2P}>
                  最少需有一名董事。董事可以是法人或自然人，
                  而且没有任何国籍上的限制；
                </li>
                <li className={s.s2P}>注册办事处必需位于开曼群岛；</li>
                <li className={s.s2P}>
                  不需申报或缴纳任何税项，除了年度牌照费；
                </li>
                <li className={s.s2P}>
                  开曼群岛政府对其离岸公司的注册资本并没有限制，但是一般做法是采用
                  50,000美元作为最少的注册资本注册，资本可划分为50,000股每股1.00美元
                  。
                </li>
              </ul>
            </div>
            <div className={s.s2Right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
