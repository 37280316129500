import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import Im1 from "./imgs/top-banner.png";
import I1 from "./imgs/icon-1.png";
import I2 from "./imgs/icon-2.png";
import I3 from "./imgs/icon-3.png";
import I4 from "./imgs/icon-4.png";

function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState(s.anLeft);
  const [an2, setAn2] = React.useState(s.anRight);
  const [an3, setAn3] = React.useState(s.anBottom);

  React.useEffect(() => {
    setAn1(`${s.anLeft} ${s.anLeftNormal}`);
    setAn2(`${s.anRight} ${s.anRightNormal}`);
    setAn3(`${s.anBottom} ${s.anBottomNormal}`);
  });

  return (
    <div className={s.wrap}>
      <div className={s.full}>
        <img src={Im1} />
        <div className={s.fullContent}>
          <div className={s.content}>
            <h2 className={an1}>自贸区</h2>
            <h2 className={`${s.h2Sub} ${an2}`}>FTZ</h2>
            <h3 className={an3}>自贸区公司注册</h3>
            <h3 className={an3}>Company Registration In Free Trade Zone</h3>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.s1}>
          <h2 className={s.title}>注册自贸区公司好处</h2>
          <div className={s.subTitle}>
            Benefits Of Incorporation In Free Trade Zone
          </div>
          <div className={s.s1Content}>
            <div className={s.s1Content1}>
              <div className={s.s1Content1Item}>
                <img src={I1} />
                <div className={s.s1Content1ItemValue}>
                  保税功能所谓保税，就是可以暂免税费，人民币外币自由兑换。
                </div>
              </div>
              <div className={s.s1Content1Item}>
                <img src={I2} />
                <div className={s.s1Content1ItemValue}>
                  安全的金融环境更方便投融资，更开放更安全的金融环境。
                </div>
              </div>
            </div>
            <div className={s.s1Content2}>
              <div className={s.s1Content2Item}>
                <img src={I3} />
                <div className={s.s1Content1ItemValue}>
                  节约企业成本退税快、 返税高、转口免费、大大节约企业成本。
                </div>
              </div>
              <div className={s.s1Content2Item}>
                <img src={I4} />
                <div className={s.s1Content2ItemValue}>
                  提高企业知名度提高企业知名度、占领国内市场、辐射国际市场。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
